import React, { useState, useRef, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { apiEndPoint } from "../../constants/api.constans";
import useLog, { useApiErrorLog, useLogEffect } from "../../hooks/useAnalytics";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

// Define the types for the props
interface PromptTextEntryProps {
  data: { title?: string; id?: string; placeholder: string };
  onChange: (data: { title: string; id: string }) => void;
  deleteUserEntry: () => void;
}

const PromptTextEntry: React.FC<PromptTextEntryProps> = ({ data = {}, onChange, deleteUserEntry }) => {
  const { title = "", id = "", placeholder = "" } = data;
  const [value, setValue] = useState<string>(title);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { config } = useRemoteConfig();

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      onChange({ title: value, id });
    }, 1000);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [value]);

  return (
    <div className="flex justify-center items-center mb-4">
      <div className="collapse bg-base-200">
        <input type="checkbox" />
        <div className="collapse-title text-xl font-medium">{title || config.locale_promt_user_input_no_name_default_title}</div>
        <div className="collapse-content flex items-center space-x-2">
          <input onChange={(e) => setValue(e.target.value)} value={value} className="input input-bordered input-primary w-full" placeholder={placeholder} />
        </div>
      </div>
      <button className="btn btn-circle ml-4" onClick={() => deleteUserEntry(id)}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );
};

interface TextEntryProps {
  data: { value?: string; id: string; placeholder?: string };
  onChange: (data: { value: string; id: string }) => void;
}

const TextEntry: React.FC<TextEntryProps> = ({ data, onChange, deleteText, index }) => {
  const [value, setValue] = useState<string>(data.value || "");
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      onChange({ value, id: data.id });
    }, 1000);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [value]);

  return (
    <div className="flex justify-center items-center mb-4">
      <textarea key={data.id} className="textarea textarea-bordered w-full" value={value} onChange={(e) => setValue(e.target.value)} rows={4} placeholder={data.placeholder} />
      {!!index && (
        <button className="btn btn-circle ml-4" onClick={() => deleteText(data.id)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      )}
    </div>
  );
};

interface TemplateItem {
  id: string;
  type: "text" | "entry";
  value?: string;
  title?: string;
  placeholder?: string;
}

interface TemplateEditProps {
  template: { metadata: { name: string; id: string; description: string }; data: TemplateItem[] };
  setTempalte: React.Dispatch<React.SetStateAction<{ metadata: { name: string }; data: TemplateItem[] }>>;
}

const TemplateEdit: React.FC<TemplateEditProps> = ({ template, setTempalte }) => {
  const { config } = useRemoteConfig();
  const [metaData, setMetadata] = useState(template.metadata);
  const [templateData, setTemplateData] = useState<TemplateItem[]>(template.data);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setTempalte({ metadata: metaData, data: templateData });
    }, 100);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData, templateData]);

  const addNewTextArea = () => {
    setTemplateData((prev) => [...prev, { id: uuidv4(), type: "text", value: "", placeholder: config.locale_continue_prompt_placeholder }]);
  };

  const addNewUserEntry = () => {
    setTemplateData((prev) => [...prev, { id: uuidv4(), type: "entry", title: "", placeholder: config.locale_prompt_iser_input_placeholder }]);
  };

  const updateUserEntry = ({ title, id }: { title: string; id: string }) => {
    setTemplateData((prev) => prev.map((item) => (item.id === id ? { ...item, title } : item)));
  };

  const updateText = ({ value, id }: { value: string; id: string }) => {
    setTemplateData((prev) => prev.map((item) => (item.id === id ? { ...item, value } : item)));
  };

  const deleteText = (id: string) => {
    setTemplateData((prev) => prev.filter((item) => item.id !== id));
  };

  // Function to delete a user entry based on its ID
  const deleteUserEntry = (id: string) => {
    setTemplateData((prev) => prev.filter((item) => item.id !== id));
  };

  const lastEntryType = templateData?.[templateData?.length - 1]?.type;

  return (
    <div>
      <label className="input input-bordered flex items-center gap-2 mb-4 r">
        {config.locale_add_new_genie_name_input_name}
        <input
          type="text"
          className="grow"
          placeholder={config.locale_add_new_genie_name_input_placeholder}
          value={metaData.name}
          onChange={(event) => setMetadata((md) => ({ ...md, name: event.target.value }))}
        />
      </label>
      <label className="input input-bordered flex items-center gap-2 mb-8 h-[auto]">
        {config.locale_add_new_genie_description_input_name}
        <textarea
          className="textarea textarea-ghost grow mt-2 mb-2 outline-none border-none focus:outline-none focus:border-none focus:ring-none"
          rows={2}
          type="text"
          // className="grow"
          placeholder={config.locale_add_new_genie_description_input_placeholder}
          value={metaData.description}
          onChange={(event) => setMetadata((md) => ({ ...md, description: event.target.value }))}
        />
      </label>
      <br />
      <div className="divider divider-neutral">{config.locale_create_genie_ai_prompt_divider}</div>
      {templateData.map((t, index) =>
        t.type === "text" ? (
          <TextEntry deleteText={deleteText} key={t.id} data={t} onChange={updateText} index={index} />
        ) : (
          <PromptTextEntry deleteUserEntry={deleteUserEntry} key={t.id} data={t} onChange={updateUserEntry} index={index} />
        )
      )}
      {/* <div className="flex flex-col md:flex-row md:justify-between mt-4 mb-8"> */}
      <div className="flex flex-col md:flex-row md:justify-between mt-4 mb-8 gap-4">
        <button className="btn w-full md:w-48 btn-outline" onClick={addNewUserEntry}>
          {config.locale_add_new_user_input_button}
        </button>

        <button disabled={lastEntryType === "text"} className="btn btn-primary mb-2 md:mb-0 w-full md:w-48 btn-outline" onClick={addNewTextArea}>
          {config.locale_add_new_text_prompt_input_button}
        </button>
      </div>
    </div>
  );
};

const CreateNew: React.FC = () => {
  const { config } = useRemoteConfig();
  const initTempalte = {
    metadata: { name: "", description: "" },
    data: [{ id: uuidv4(), type: "text", value: "", placeholder: config.locale_start_typing_prompt_placeholder }],
  };
  const [template, setTempalte] = useState(initTempalte);
  const state = useContext(AuthContext).state;
  const dispatch = useContext(AuthContext).dispatch;
  // const [showLoader, setShowLoader] = useState(false);
  const [publicOrPrivate, setPublicOrPrivate] = useState("Public");

  const { post } = useAxios();

  const navigate = useNavigate();
  useLogEffect({ event: "CreateTemplate_View" }, []);

  const handlePublish = () => {
    // setShowLoader(true);
    if (!template?.metadata?.name) {
      template.metadata.name = "Untitled Genie";
    }

    if (publicOrPrivate === "Public") {
      // Publish Public
      dispatch({ type: "LOADING_START", payload: "creatingPublicGenie" });
      useLog({ event: "Template_Public_Publish" });
      post(apiEndPoint + "/publishTemplateApi", template)
        .then((res) => {
          dispatch({ type: "LOADING_END", payload: "creatingPublicGenie" });
          dispatch({ type: "SET_TOAST", payload: "genieCreated" });
          navigate(`/genie/${res?.data?.metadata?.id}`);

          // setShowLoader(false);
        })
        .catch(() => {
          // setShowLoader(false);
          dispatch({ type: "SET_TOAST", payload: "failedToCreateGenie" });

          dispatch({ type: "LOADING_END", payload: "creatingPublicGenie" });
        });
    } else {
      useLog({ event: "Template_Private_Publish" });
      dispatch({ type: "LOADING_START", payload: "creatingPublicGenie" });
      post(apiEndPoint + "/publishPrivateTemplateApi", template)
        .then((res) => {
          dispatch({ type: "LOADING_END", payload: "creatingPublicGenie" });
          dispatch({ type: "SET_TOAST", payload: "genieCreated" });
          navigate(`/genie/${res?.data?.metadata?.id}`);
        })
        .catch((err) => {
          dispatch({ type: "SET_TOAST", payload: "failedToCreateGenie" });

          dispatch({ type: "LOADING_END", payload: "creatingPublicGenie" });
          useApiErrorLog({ event: "publishPrivateTemplateApi_Failed", error: err, template: JSON.stringify(template) });
        })
        .finally(() => {
          // setShowLoader(false);
        });
      // Publish Private
    }
  };

  // if (showLoader) return <FullPageLoader />;

  return (
    <div className="flex flex-col w-full max-w-[95vw] md:max-w-[50vw]">
      <Helmet>
        <title>{`${config.helmet_title_create_genie} - ${config.appname}`}</title>
      </Helmet>
      <h3 className="text-2xl font-bold mb-4 text-center mb-8">{config.locale_create_template_title}</h3>
      <TemplateEdit template={template} setTempalte={setTempalte} />
      <div className="flex mt-4">
        <button className="btn btn-accent flex-grow" onClick={handlePublish}>
          {config.locale_create_template_publish_button}
        </button>
        <select className="select select-primary w-auto ml-4" onChange={(e) => setPublicOrPrivate(e.target.value)}>
          <option selected={publicOrPrivate === "Public"}>{config.locale_create_template_public_dropdown}</option>
          <option selected={publicOrPrivate === "Private"}>{config.locale_create_template_private_dropdown}</option>
        </select>
      </div>
    </div>
  );
};

export default CreateNew;
