import { useNavigate } from "react-router-dom";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";
import useAuth from "../../hooks/useAuth";

const Drawer = () => {
  const { initateSignoutOfGoogle } = useAuth();
  const { config } = useRemoteConfig();
  const navigate = useNavigate();

  return (
    <div className="drawer">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col items-center justify-center">
        {/* Page content here */}
        <label htmlFor="my-drawer-2" className="drawer-button md:hidden p-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
          </svg>{" "}
        </label>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>
        <ul className="menu bg-base-200 text-base-content min-h-full w-80 pt-10">
          {/* Sidebar content here */}
          <li>
            <a onClick={() => navigate("/my-collections")}>{config.locale_header_menu_my_collection}</a>
          </li>
          <li>
            <a onClick={initateSignoutOfGoogle}> {config.locale_header_menu_signout}</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Drawer;
