import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import logo from "../../assets/logo.png";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

const MainLogo: React.FC = () => {
  const { dispatch } = useContext(AuthContext);
  const { config } = useRemoteConfig();

  useEffect(() => {
    dispatch({ type: "SET_MAIN_LOGO_VISIBILITY", payload: true });
    return () => {
      dispatch({ type: "SET_MAIN_LOGO_VISIBILITY", payload: false });
    };
  }, []);
  return (
    <div className="flex justify-center items-center">
      <img src={logo} className="h-16 mr-4" />
      <h1 className="text-5xl font-bold text-center my-10  bg-gradient-to-r from-custom-start to-custom-end inline-block text-transparent bg-clip-text">{config.appname}</h1>
    </div>
  );
};

export default MainLogo;
