import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Gif from "../Gif";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

const defaultLoadingText = "Hold on....";

const FullPageLoader = () => {
  const loadingState = useContext(AuthContext).state.loadingState;
  const lastLoadingState = loadingState[0];
  const { config } = useRemoteConfig();

  if (!lastLoadingState) return;

  const text = config?.[`locale_full_page_loader_${loadingState}`] || defaultLoadingText;

  return (
    <div className="flex items-center justify-center fixed w-screen h-screen top-0 bg-black bg-opacity-50 z-10">
      <div className="flex flex-col items-center">
        <div className="flex justify-center w-full">
          <Gif icon="updating" />
        </div>
        {/* <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-500"></div> */}
        <p className="mt-4 text-lg text-gray-700 text-white">{text}</p>
      </div>
    </div>
  );
};

export default FullPageLoader;
