import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import fetching from "./files/fetching.gif";
import thinking from "./files/thinking.gif";
import updating from "./files/updating.gif";
import search from "./files/search.gif";
import noSearch from "./files/noSearch.png";
import scrollDown from "./files/scrollDown.png";
const Gif = ({ icon = "thinking", height = "150px", width = "150px", classNames = "" }) => {
  const gifMap = {
    // fetching,
    // thinking,
    // updating,
    scrollDown,
    search,
    noSearch,
  };
  return <img src={gifMap[icon] || search} height={height} width={width} className={`m-0 ${classNames}`} />;
};

export default Gif;
