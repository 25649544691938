import Markdown from "react-markdown";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

const StaticPage = () => {
  const { pageId } = useParams();
  console.log("🚀 ~ StaticPage ~ pageId:", pageId);
  const { config } = useRemoteConfig();
  const configKey = `data_static_page_${pageId.replaceAll("-", "")}`;
  const data = config[configKey];
  console.log("🚀 ~ StaticPage ~ pageId:", configKey);

  if (!data)
    return (
      <>
        <Helmet>
          <title>{`${config[`helmet_title_page_not_available`]} - ${config.appname}`}</title>
        </Helmet>
        <p>{config.locale_static_page_not_available}</p>
      </>
    );

  return (
    <div className="flex flex-col w-full max-w-[95vw] md:max-w-[50vw] prose mt-[10vh]">
      <Helmet>
        <title>{`${config[`helmet_title_page_${pageId.replaceAll("-", "")}`]} - ${config.appname}`}</title>
      </Helmet>
      <Markdown>{data.replaceAll("::||::", "\n")}</Markdown>
    </div>
  );
};

export default StaticPage;
