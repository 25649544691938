import { doc, getDoc } from "firebase/firestore";
import { db } from "../init";

export async function getAllTemplatesOfUser({ uid }) {
  try {
    // Create a reference to the document
    const docRef = doc(db, "usersCollections", uid);

    // Fetch the document
    const docSnap = await getDoc(docRef);

    // Check if the document exists
    if (docSnap.exists()) {
      
      return { id: docSnap.id, ...docSnap.data() }; // Return the document data
    } else {
      
      return null; // Document not found
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
  }
}
