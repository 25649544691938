import { useEffect, useState, createContext, useContext } from "react";
import { getRemoteConfig, fetchAndActivate, getValue, getAll } from "firebase/remote-config";
import { app } from "../firebase/init";

// Initialize Firebase app
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000;

// Create Context for Remote Config
const RemoteConfigContext = createContext();

// Custom hook to use Remote Config
export const useRemoteConfig = () => {
  return useContext(RemoteConfigContext);
};

// Provider component
export const RemoteConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRemoteConfig = async () => {
      try {
        await fetchAndActivate(remoteConfig);
        const allValues = getAll(remoteConfig);

        // Convert all values to a simple object
        const configValues = {};
        for (const key in allValues) {
          configValues[key] = allValues[key]._value;
        }

        setConfig(configValues);
      } catch (error) {
        console.error("Failed to fetch remote config", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRemoteConfig();
  }, []);

  return <RemoteConfigContext.Provider value={{ config, loading }}>{children}</RemoteConfigContext.Provider>;
};
