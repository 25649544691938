import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";
import Drawer from "../Drawer";
import useScrollPage from "../../hooks/useScrollPage";
import useInitialUserCollectionDataFetch from "../../hooks/useInitialUserCollectionDataFetch";
import useCredits from "../../hooks/useCredits";
import logo from "../../assets/logo.png";

const Header = () => {
  const { initateSignoutOfGoogle } = useAuth();
  const state = useContext(AuthContext).state;
  const userCredit = state?.userCredit;
  const mainLogoVisible = state?.mainLogoVisible;
  const creditBalance = userCredit?.creditBalance;
  const { config } = useRemoteConfig();
  try {
    useCredits();
  } catch (err) {}

  const navigate = useNavigate();
  useScrollPage();

  const isMyCollectionPage = window.location.pathname === "/my-collections";

  return (
    <div className="navbar max-h-[90px] bg-base-100 fixed top-0 left-0 right-0 z-10 w-full">
      <div className="navbar-start w-fit">
        <div className="dropdown md:hidden">
          {/* <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </div> */}
          <Drawer />
          {/* <ul tabIndex={0} className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
            <li>
              <a onClick={() => navigate("/my-collections")}>{config.locale_header_menu_my_collection}</a>
            </li>
            <li>
              <a onClick={initateSignoutOfGoogle}> {config.locale_header_menu_signout}</a>
            </li>
          </ul> */}
        </div>
        {!mainLogoVisible && (
          <NavLink to="/">
            {/* <a className="btn btn-ghost text-xl text-white hover:bg-transparent">{config.appname}</a> */}
            <a className="btn btn-ghost text-xl text-white hover:bg-transparent">
              <img src={logo} className="h-[100%]" />
            </a>
          </NavLink>
        )}
      </div>
      <div className="navbar-center hidden md:flex">
        <ul className="menu menu-horizontal px-1">
          <li className="mb-0">
            <a className={`text-white hover:bg-transparent ${isMyCollectionPage ? "font-bold text-accent" : ""} `} onClick={() => navigate("/my-collections")}>
              {config.locale_header_menu_my_collection}
            </a>
          </li>
          <li className="mb-0">
            <a className="text-white hover:bg-transparent" onClick={initateSignoutOfGoogle}>
              {config.locale_header_menu_signout}
            </a>
          </li>
        </ul>
      </div>
      <div className="navbar-end prose ml-auto">
        <div className="flex whitespace-nowrap mr-4 justify-center items-center">
          <p className="hidden md:block">{config.label_header_credit_left}</p>
          <svg viewBox="0 0 1024 1024" className="h-6 w-6" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M597.333333 440.888889m-256 0a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z" fill="#FDD821" />
            <path
              d="M597.333333 184.888889c141.383111 0 256 114.616889 256 256s-114.616889 256-256 256-256-114.616889-256-256 114.616889-256 256-256z m0 14.222222c-133.532444 0-241.777778 108.245333-241.777777 241.777778s108.245333 241.777778 241.777777 241.777778 241.777778-108.245333 241.777778-241.777778-108.245333-241.777778-241.777778-241.777778z"
              fill="#B2181A"
            />
            <path
              d="M597.333333 279.978667c18.631111 49.422222 37.788444 83.953778 57.457778 103.637333 19.683556 19.669333 54.158222 38.755556 103.452445 57.272889-54.542222 14.947556-91.050667 31.658667-109.511112 50.104889-18.460444 18.460444-35.598222 55.395556-51.399111 110.791111-20.309333-57.799111-40.064-96.284444-59.264-115.484445-19.2-19.2-53.077333-34.332444-101.646222-45.411555 52.067556-20.053333 87.182222-39.139556 105.315556-57.272889 18.147556-18.147556 36.679111-52.693333 55.594666-103.637333z"
              fill="#FA1C3D"
            />
            <path
              d="M758.243556 440.888889c-54.542222 14.947556-91.050667 31.658667-109.511112 50.104889-18.460444 18.460444-35.598222 55.395556-51.399111 110.791111-20.309333-57.799111-40.064-96.284444-59.264-115.484445-19.2-19.2-53.077333-34.332444-101.646222-45.411555 52.067556-20.053333 87.182222-39.139556 105.315556-57.272889 18.147556-18.147556 36.679111-52.693333 55.594666-103.637333 18.631111 49.422222 37.788444 83.953778 57.457778 103.637333 19.683556 19.669333 54.158222 38.755556 103.452445 57.272889z m-158.961778-118.926222l-1.792-4.039111-0.896 2.090666c-14.833778 34.019556-29.653333 58.510222-44.8 73.656889-14.321778 14.321778-37.290667 28.643556-69.12 43.278222l-2.773334 1.237334 0.483556 0.170666c28.216889 9.557333 49.863111 21.219556 64.995556 35.228445l2.744888 2.645333c14.933333 14.933333 29.610667 39.409778 44.359112 73.628445l3.683555 8.732444 0.597333-1.692444c12.174222-33.678222 25.272889-58.140444 39.637334-73.585778l2.275555-2.375111c15.288889-15.274667 40.334222-28.899556 75.363556-41.244445l2.133333-0.753778-5.376-2.474666c-27.363556-12.856889-48.398222-26.140444-63.189333-40.021334l-2.872889-2.773333c-15.800889-15.815111-30.862222-39.68-45.454222-71.708444z"
              fill="#B2181A"
            />
            <path d="M440.888889 568.888889m-256 0a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z" fill="#FDD821" />
            <path
              d="M440.888889 312.888889c141.383111 0 256 114.616889 256 256s-114.616889 256-256 256-256-114.616889-256-256 114.616889-256 256-256z m0 14.222222c-133.532444 0-241.777778 108.245333-241.777778 241.777778s108.245333 241.777778 241.777778 241.777778 241.777778-108.245333 241.777778-241.777778-108.245333-241.777778-241.777778-241.777778z"
              fill="#B2181A"
            />
            <path
              d="M440.888889 407.978667c18.631111 49.422222 37.788444 83.953778 57.457778 103.637333 19.683556 19.669333 54.158222 38.755556 103.452444 57.272889-54.542222 14.947556-91.050667 31.658667-109.511111 50.104889-18.460444 18.460444-35.598222 55.395556-51.399111 110.791111-20.309333-57.799111-40.064-96.284444-59.264-115.484445-19.2-19.2-53.077333-34.332444-101.646222-45.411555 52.067556-20.053333 87.182222-39.139556 105.315555-57.272889 18.147556-18.147556 36.679111-52.693333 55.594667-103.637333z"
              fill="#FA1C3D"
            />
            <path
              d="M601.799111 568.888889c-54.542222 14.947556-91.050667 31.658667-109.511111 50.104889-18.460444 18.460444-35.598222 55.395556-51.399111 110.791111-20.309333-57.799111-40.064-96.284444-59.264-115.484445-19.2-19.2-53.077333-34.332444-101.646222-45.411555 52.067556-20.053333 87.182222-39.139556 105.315555-57.272889 18.147556-18.147556 36.679111-52.693333 55.594667-103.637333 18.631111 49.422222 37.788444 83.953778 57.457778 103.637333 19.683556 19.669333 54.158222 38.755556 103.452444 57.272889z m-158.961778-118.926222l-1.792-4.039111-0.896 2.090666c-14.833778 34.019556-29.653333 58.510222-44.8 73.656889-14.321778 14.321778-37.290667 28.643556-69.12 43.278222l-2.773333 1.237334 0.483556 0.170666c28.216889 9.557333 49.863111 21.219556 64.995555 35.228445l2.744889 2.645333c14.933333 14.933333 29.610667 39.409778 44.359111 73.628445l3.683556 8.732444 0.597333-1.692444c12.174222-33.678222 25.272889-58.140444 39.637333-73.585778l2.275556-2.375111c15.288889-15.274667 40.334222-28.899556 75.363555-41.244445l2.133334-0.753778-5.376-2.474666c-27.363556-12.856889-48.398222-26.140444-63.189334-40.021334l-2.872888-2.773333c-15.800889-15.815111-30.862222-39.68-45.454223-71.708444z"
              fill="#B2181A"
            />
          </svg>
          <p className="font-bold text-white">{creditBalance}</p>
        </div>
        <NavLink to="/create-new" className="no-underline hover:no-underline">
          <button className="btn btn-success md:hidden">{config.locale_header_create_button_mobile}</button>
          <button className="btn btn-success hidden md:block">{config.locale_header_create_button_desktop}</button>
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
