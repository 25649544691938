import { useContext, useEffect, useRef } from "react";
import { auth } from "../firebase/init";
import { AuthContext } from "../context/AuthContext";

export default () => {
  const state = useContext(AuthContext).state;
  const dispatch = useContext(AuthContext).dispatch;
  const previousState = useRef(false);
  const onAuthStateChanged = (d) => {
    if (!d) {
      if (previousState.current) {
        window.location.reload();
      }
      previousState.current = false;
      //user loggedout or not authenticated
      dispatch({ type: "IS_AUTHENTICATED", payload: false });
      return;
    }

    previousState.current = true;
    dispatch({ type: "IS_AUTHENTICATED", payload: true });
    dispatch({ type: "SET_USER_DATA", payload: { uid: d.uid, token: d.accessToken } });
  };
  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);
};
