import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { useContext } from "react";
import { auth } from "../firebase/init";
import { AuthContext } from "../context/AuthContext";

type AuthHookReturn = {
  initiateSigninWithGoogle: () => Promise<void>;
  initateSignoutOfGoogle: () => Promise<void>;
};

const useAuth = (): AuthHookReturn => {
  const state = useContext(AuthContext).state;
  const dispatch = useContext(AuthContext).dispatch;

  const initiateSigninWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        const uid = user.uid;
        
        // dispatch({ type: "GOOGLE_ACCESS_TOKEN", payload: token });
        // dispatch({ type: "USER_DATA", payload: user });
        // dispatch({ type: "AUTHENTICATED_WITH_GOOGLE", payload: true });

        // Here you can handle user data as needed
        // e.g., save user info to your database or update UI
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error Code:", errorCode);
        console.error("Error Message:", errorMessage);
        return Promise.reject(error);
      });
  };

  const initateSignoutOfGoogle = () => {
    return signOut(auth)
      .then(() => {
        // Sign-out successful.
        
        // You can also dispatch actions or update UI here
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error Code:", errorCode);
        console.error("Error Message:", errorMessage);
      });
  };
  return { initiateSigninWithGoogle, initateSignoutOfGoogle };
};

export default useAuth;
