const InputWithClose = ({ setQuery, query, handleClear, wrapperClassName = "", inputClassName = "", placeholder = "", alwaysShowClose = false }) => {
  return (
    <div className={`relative max-w-md w-full ${wrapperClassName}`}>
      <input
        type="text"
        className={`input input-bordered w-full pr-10 ${inputClassName}`} // Add padding to the right for the clear button
        placeholder={placeholder}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {/* Clear button as an "x" character */}
      {(query || alwaysShowClose) && (
        <button className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-transparent" onClick={handleClear} aria-label="Clear">
          x
        </button>
      )}
    </div>
  );
};

export default InputWithClose;
