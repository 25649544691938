import { useEffect, useState } from "react";
import useGetDataWithCache from "../../hooks/useGetDataWithCache";
import useAxios from "../../hooks/useAxios";
import { apiEndPoint } from "../../constants/api.constans";
import FullPageLoader from "../FullPageLoader";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

const DeleteCollectionConfirmationPopup = ({ onClose = () => {}, tempalateId, confirmationCallback = () => {} }) => {
  const [showLoader, setShowLoader] = useState(false);
  const { post } = useAxios();
  const { config } = useRemoteConfig();

  useEffect(() => {
    if (showLoader) return;
    document.getElementById("my_modal_3").showModal();
  }, [showLoader]);

  if (showLoader) return <FullPageLoader />;

  // const handleRemoval = () => {
  //   showLoader(true)
  //   post(apiEndPoint + '/removeTemplateFromCollection').then(() => {

  //   }).finally(() => {
  //     showLoader(false)
  //   })
  // }

  return (
    <dialog id="my_modal_3" className="modal" onClose={onClose}>
      <div className="modal-box prose">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button onClick={onClose} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <h2 className="text-center mt-0">{config.locale_delete_collection_popup_title}</h2>
        <p>{config.locale_delete_collection_popup_description}</p>
        <button className="btn btn-error" onClick={confirmationCallback}>
          {config.locale_delete_collection_popup_confirm_button_text}
        </button>
      </div>
    </dialog>
  );
};

export default DeleteCollectionConfirmationPopup;
