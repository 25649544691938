import axios from "axios";
import useAccessToken from "./useAccessToken";
import { useEffect } from "react";

const useAxios = () => {
  return {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    Delete: axios.delete,
  };
};

export const useAxiosInterceptor = () => {
  const getAccessToken = useAccessToken();

  const axiosInterceptor = (config) => {
    const accessToken = getAccessToken();
    config.headers["Authorization"] = `Bearer ${accessToken || "token-not-available"}`;
    return config;
  };

  useEffect(() => {
    const myInterceptor = axios.interceptors.request.use(axiosInterceptor);
    return () => axios.interceptors.request.eject(myInterceptor);
  }, []);
};

export default useAxios;
