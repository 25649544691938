import { useContext, useEffect, useState } from "react";
import useGetDataWithCache from "../../hooks/useGetDataWithCache";
import useAxios from "../../hooks/useAxios";
import { apiEndPoint } from "../../constants/api.constans";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";
import { AuthContext } from "../../context/AuthContext";
import InputWithClose from "../InputWithClose";

const Modal = ({ onClose = () => {}, tempalateId }) => {
  const { getData, data } = useGetDataWithCache("usersColellections");
  const { clearCache: clearCollectionDetailsCache } = useGetDataWithCache("collectionDetails");

  const [createCollectionFlow, setCreateCollectionFlow] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const { post } = useAxios();
  const { config } = useRemoteConfig();

  const dispatch = useContext(AuthContext).dispatch;
  const loadingState = useContext(AuthContext).state.loadingState;

  useEffect(() => {
    if (loadingState?.length) return;
    document.getElementById("my_modal_3").showModal();
    getData();
  }, [loadingState?.length]);

  const usersCollections = (data?.collections || []).filter((c) => !c.nonEditableByUser);

  const createNewCollection = () => {
    dispatch({ type: "LOADING_START", payload: "createNewCollection" });
    post(apiEndPoint + "/createCollectionApi", { name: newCollectionName })
      .then(() => {
        setCreateCollectionFlow(false);
        setNewCollectionName("");
        getData({}, true).finally(() => {
          dispatch({ type: "LOADING_END", payload: "createNewCollection" });
          dispatch({ type: "SET_TOAST", payload: "collectionCreationSuccess" });
        });
      })
      .catch(() => {
        dispatch({ type: "SET_TOAST", payload: "collectionCreationFailed" });
        dispatch({ type: "LOADING_END", payload: "createNewCollection" });
      });
  };

  const addToCollection = (collectionId) => {
    // setShowLoader(true);
    dispatch({ type: "LOADING_START", payload: "addingTemplateToCollection" });
    post(apiEndPoint + "/addTemplateToCollection", { collectionId, tempalateId })
      .then(() => {
        onClose();
        clearCollectionDetailsCache(collectionId);
        // getData({}, true); // call template details instead
        dispatch({ type: "SET_TOAST", payload: "templateAddedToCollectionSuccess" });
        dispatch({ type: "LOADING_END", payload: "addingTemplateToCollection" });
      })
      .catch(() => {
        dispatch({ type: "SET_TOAST", payload: "templateAddedToCollectionFailed" });
        dispatch({ type: "LOADING_END", payload: "addingTemplateToCollection" });
        // setShowLoader(false);
      });
  };

  // if (showLoader) return <FullPageLoader />;

  if (loadingState?.length) return <></>;

  return (
    <dialog id="my_modal_3" className="modal" onClose={onClose}>
      <div className="modal-box prose">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button onClick={onClose} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <h2 className="m-0 text-center mb-4">{config.locale_add_to_your_collection}</h2>
        <div className="max-h-[60vh] overflow-scroll">
          {Boolean(data?.collections?.length) && (
            <table className="table">
              <tbody>
                {usersCollections.map((c) => (
                  <tr className="hover" onClick={() => addToCollection(c.id)}>
                    <td className="text-center">{c.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {!createCollectionFlow && (
          <button className="mt-4 btn btn-success" onClick={() => setCreateCollectionFlow(true)}>
            {config.locale_create_collection_popup_create_button_text}
          </button>
        )}
        {createCollectionFlow && (
          <div className="flex flex-col mt-4 md:flex-row ">
            <InputWithClose
              handleClear={() => {
                setCreateCollectionFlow(false);
                setNewCollectionName("");
              }}
              query={newCollectionName}
              setQuery={(value) => {
                setNewCollectionName(value);
              }}
              wrapperClassName="w-full"
              placeholder={config.locale_my_collection_page_new_collection_name_placeholder}
              alwaysShowClose
            />
            <button className="btn btn-success md:ml-4 mt-3 md:mt-0 " disabled={!newCollectionName} onClick={createNewCollection}>
              {config.locale_my_collection_page_create_collection_button}
            </button>
          </div>
        )}
      </div>
    </dialog>
  );
};

export default Modal;
