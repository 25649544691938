import React from "react";
import SigninWithGoogle from "../../components/SigninWithGoogle";
import MainLogo from "../../components/MainLogo";
import Gif from "../../components/Gif";
import Footer from "../../components/Footer";

const LandingPage: React.FC = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 min-h-[90vh] items-center w-full p-8 prose max-w-none relative">
        <div className="p-4 flex flex-col justify-center items-center ">
          <MainLogo />
          <h1 className="text-4xl font-bold text-center mb-8">Create custom AI genie for faster workflows.</h1>
          <p className="mt-0 text-center">Make your repetitive prompts into a template (Genie) and get response faster everytime.</p>
          <SigninWithGoogle />
        </div>
        <div className="p-4">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/1032791317?h=99453e1bb0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              title="MakeAGenie - Custom AI Genies for faster workflows."
            ></iframe>
          </div>
        </div>
        {/* <Gif icon="scrollDown" classNames="absolute bottom-0 left-[50%] transform -translate-x-1/2" height="50px" width="50px" /> */}
      </div>
      {/* 
      <div className="grid grid-cols-1 md:grid-cols-2 min-h-[90vh] items-center prose max-w-none relative">
        <div className="p-4">
          <img src="path/to/your/image.jpg" alt="Description" className="w-full h-full object-cover" />
        </div>
        <div className="p-4">
          <h2 className="text-3xl font-semibold">How it Works</h2>
          <ul className="list-disc ml-6 mt-4">
            <li>Create New Genie</li>
            <li>Add fixed parts of promts</li>
            <li>Add dynamic parts of prompt as user input</li>
            <li>Publish genie. Your'e DONE.</li>
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 min-h-[90vh] items-center max-w-none relative">
        <div className="p-4">
          <h2 className="text-3xl font-semibold">Features</h2>
          <p className="mt-2">Discover the powerful tools we provide to enhance your productivity.</p>
        </div>
        <div className="p-4">
          <img src="path/to/your/feature-image.jpg" alt="Feature Image" className="w-full h-full object-cover" />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center min-h-[90vh]">
        <h3 className="text-3xl font-semibold">Get Started Today!</h3>
        <p className="mt-2">Join us and take your productivity to the next level.</p>
        <button className="btn btn-primary mt-4">Continue with Google</button>
      </div> */}
      <Footer />
    </>
  );
};

export default LandingPage;

// <div className="flex flex-col items-center w-full min-h-screen bg-gray-100">
//   {/* First Section: Tagline and Sign In Button */}
//   <div className="flex flex-col items-center justify-center w-full h-screen bg-blue-500 text-white p-4">
//     <h1 className="text-4xl font-bold mb-4">Welcome to Our App</h1>
//     <button className="btn btn-primary">Sign In</button>
//   </div>

//   {/* Second Section: Simple Demo of App */}
//   <div className="flex flex-col items-center justify-center w-full h-screen bg-white text-gray-800 p-4">
//     <h2 className="text-3xl font-bold mb-4">App Demo</h2>
//     <p className="max-w-md text-center">Here you can showcase a simple demo of your app. This could be a video, an image carousel, or an interactive element.</p>
//   </div>

//   {/* Third Section: Major Offerings */}
//   <div className="flex flex-col items-center justify-center w-full h-screen bg-green-500 text-white p-4">
//     <h2 className="text-3xl font-bold mb-4">Our Offerings</h2>
//     <ul className="list-disc list-inside">
//       <li>Feature 1: Description of feature 1.</li>
//       <li>Feature 2: Description of feature 2.</li>
//       <li>Feature 3: Description of feature 3.</li>
//       {/* Add more offerings as needed */}
//     </ul>
//   </div>
// </div>
