export function removeElementAtIndex(arr, index) {
  // Check if the index is valid
  if (index < 0 || index >= arr.length) {
    throw new Error("Index out of bounds");
  }

  // Create a new array excluding the element at the specified index
  return arr.filter((_, i) => i !== index);
}

export function scrollToChildHorizontally(parentId, childId) {
  
  const parent = document.getElementById(parentId);
  const child = document.getElementById(childId);
  if (!parent || !child) return;

  // Get the bounding rectangles of both elements
  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();

  // Calculate the child's position relative to the parent
  const childPosition = childRect.left - parentRect.left + parent.scrollLeft;
  

  // Scroll the parent div to bring the child into view
  parent.scrollLeft = childPosition - parentRect.width / 4;
}
export function scrollToChildVertically(parentId, childId) {
  
  const parent = document.getElementById(parentId);
  const child = document.getElementById(childId);
  if (!parent || !child) return;

  // Get the bounding rectangles of both elements
  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();

  // Calculate the child's position relative to the parent
  const childPosition = childRect.top - parentRect.top + parent.scrollTop;
  

  // Scroll the parent div to bring the child into view
  parent.scrollTop = childPosition - parentRect.height / 4;
}
