import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

const RemoveTemplateFromCollectionModal = ({ onClose = () => {}, tempalateId, confirmationCallback = () => {} }) => {
  const loadingState = useContext(AuthContext).state.loadingState;
  const { config } = useRemoteConfig();

  useEffect(() => {
    if (loadingState?.length) return;
    document.getElementById("my_modal_3").showModal();
    // getData();
  }, [loadingState?.length]);

  if (loadingState?.length) return <></>;

  // const handleRemoval = () => {
  //   showLoader(true)
  //   post(apiEndPoint + '/removeTemplateFromCollection').then(() => {

  //   }).finally(() => {
  //     showLoader(false)
  //   })
  // }

  return (
    <dialog id="my_modal_3" className="modal" onClose={onClose}>
      <div className="modal-box">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button onClick={onClose} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <h2 className="font-bold text-lg">{config.locale_remove_template_from_collection_modal_title}</h2>
        <p>{config.locale_remove_template_from_collection_modal_description}</p>
        <button onClick={confirmationCallback}>{config.locale_remove_template_from_collection_modal_remove_button}</button>
      </div>
    </dialog>
  );
};

export default RemoveTemplateFromCollectionModal;
