import React, { useEffect, useState } from "react";
import { scrollToChildHorizontally } from "../../utils/utils";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

interface TabData {
  id: string;
  label: string;
}

interface TabProps {
  data: TabData[];
  children: React.ReactElement; // Expecting a single child
}

const TabPage: React.FC<TabProps> = ({ data, children }) => {
  const getDefaultSelectedTab = () => {
    const previouslySelectedTabFromLocal = localStorage.getItem("homeTab") || "";
    const previouslySelectedTabExists = data.find((d) => d.id === previouslySelectedTabFromLocal);
    return previouslySelectedTabExists?.id || data[0].id;
  };

  const [selectedTab, setSelectedTab] = useState(getDefaultSelectedTab);

  const handleTabChange = (id: string) => {
    setSelectedTab(id);
    localStorage.setItem("homeTab", id);
  };

  const handleScrolling = () => {
    scrollToChildHorizontally("tab_wrapper", `tabItem_${selectedTab}`);
  };

  useEffect(handleScrolling, [selectedTab]);

  const { config } = useRemoteConfig();

  // Find the currently selected tab's label
  const currentTabName = data.find((tab) => tab.id === selectedTab)?.name;

  return (
    <>
      <div id="tab_wrapper" className="tabs tabs-lifted overflow-x-scroll overflow-y-hidden justify-center mt-6">
        {data.map((tab) => {
          let tabName = tab?.name;
          if (tab?.type === "ownPublicTemplate") tabName = config.locale_my_public_template_name;
          else if (tab?.type === "ownPrivateTemplate") tabName = config.locale_my_private_template_name;
          else if (tab?.type === "ownfavouriteTemplate") tabName = config.locale_my_favourite_template_name;

          return (
            <React.Fragment key={tab.id}>
              <input
                id={`tabItem_${tab.id}`}
                type="radio"
                name="tabs"
                className={`tab whitespace-nowrap pb-6 ${selectedTab === tab.id ? "tab-active-bg" : ""}`}
                style={{ width: "fit-content", fontWeight: selectedTab === tab.id ? 900 : undefined }}
                aria-label={tabName}
                checked={selectedTab === tab.id}
                onChange={() => handleTabChange(tab.id)}
              />
              {/* <label className="tab-label">{tab.label}</label> */}
            </React.Fragment>
          );
        })}
      </div>
      <div className="bg-base-200 border-base-300 rounded-box p-6">
        {React.cloneElement(children, {
          selectedTab,
          currentTabName,
        })}
      </div>
    </>
  );
};

export default TabPage;
