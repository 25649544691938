export const firebaseConfig = {
  apiKey: "AIzaSyBPe8ArS6bydrPTek3UI_mHWMy1l2Mavew",
  authDomain: "vegamta-dev.firebaseapp.com",
  projectId: "vegamta-dev",
  storageBucket: "vegamta-dev.appspot.com",
  messagingSenderId: "245694640717",
  appId: "1:245694640717:web:71d35ab0790d0f25c06204",
  measurementId: "G-RWS6ZH8RBX",
};

export const algoliaAppId = "DJV20MHJHL";
export const algoliaApiKey = "48b436ecd7d34de4be4790e8a43d4ca0";
export const algoliaindexName = "templates";
