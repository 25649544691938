import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

const toastMap = {
  privateToPublicTemplateSuccess: {
    text: "Template made public",
    isSuccess: true,
  },
  privateToPublicTemplateFailure: {
    text: "Something went wrong while making your template public.",
    isSuccess: false,
  },
  publicToPrivateTemplateSuccess: {
    text: "Template made private",
    isSuccess: true,
  },
  publicToPrivateTemplateFailure: {
    text: "Something went wrong while making your template private.",
    isSuccess: false,
  },
  promptCopied: {
    text: "Prompt copied to Clipboard.",
    isSuccess: true,
  },
  shareLinkCopied: {
    text: "Share link copied to Clipboard.",
    isSuccess: true,
  },
  collectionCreationSuccess: {
    text: "Collection created succesfully.",
    isSuccess: true,
  },
  collectionCreationFailed: {
    text: "Oops! Failed to create collection. Try again after reloading.",
    isSuccess: false,
  },
  templateAddedToCollectionSuccess: {
    text: "Template added succesfully.",
    isSuccess: true,
  },
  templateAddedToCollectionFailed: {
    text: "Failed! Unable to add template to collection.",
    isSuccess: false,
  },
  removingRecipeFromCollectionSuccess: {
    text: "Template removed from collection.",
    isSuccess: true,
  },
  removingRecipeFromCollectionFailure: {
    text: "Failed! Unable to remove template from collection.",
    isSuccess: false,
  },
  deleteCollectionSuccess: {
    text: "Collection deleted.",
    isSuccess: true,
  },
  deleteCollectionFailed: {
    text: "Failed! Unable to delete collection",
    isSuccess: false,
  },
  removeCollectionFromMyCollectionSuccess: {
    text: "Collection removed from 'My Collections'.",
    isSuccess: true,
  },
  removeCollectionFromMyCollectionFailed: {
    text: "Failed! Unable to remove collection",
    isSuccess: false,
  },
  publishingCollectionSuccess: {
    text: "Collection made public.",
    isSuccess: true,
  },
  publishingCollectionFailed: {
    text: "Failed! Unable to make collection public.",
    isSuccess: false,
  },
  makeCollectionPrivateSuccess: {
    text: "Collection made private.",
    isSuccess: true,
  },
  makeCollectionPrivateFailed: {
    text: "Failed! Unable to make collection private.",
    isSuccess: false,
  },

  updatingCollectionSuccess: {
    text: "Collection updated.",
    isSuccess: true,
  },
  updatingCollectionFailed: {
    text: "Failed! Unable to update collection",
    isSuccess: false,
  },
  deleteGenieSuccess: {
    text: "Genie Gone :( Succesfully deleted & will be removed from collections sooon.",
    isSuccess: true,
  },
  deleteGenieFailed: {
    text: "Failed! Unable to delete genie",
    isSuccess: false,
  },
  addToMyCollectionSuccess: {
    text: "Added to my collection",
    isSuccess: true,
  },
  addToMyCollectionFailed: {
    text: "Failed! Unable to add to my collection",
    isSuccess: false,
  },
  genieCreated: {
    text: "Ginie created",
    isSuccess: true,
  },
  failedToCreateGenie: {
    text: "Failed! Unable to create genie",
    isSuccess: false,
  },
  updateGenieSuccess: {
    text: "Ginie updated",
    isSuccess: true,
  },
  updateGenieFailed: {
    text: "Failed! Unable to create genie",
    isSuccess: false,
  },
  loginFailed: {
    text: "Login Failed",
    isSuccess: false,
  },
};

const Toast = () => {
  const loadingState = useContext(AuthContext).state.toast;
  const dispatch = useContext(AuthContext).dispatch;
  const { config } = useRemoteConfig();

  const text = config?.[`locale_toast_${loadingState}`];
  const isSuccess = toastMap[loadingState]?.isSuccess;

  const timer = useRef();
  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dispatch({ type: "SET_TOAST", payload: "" });
    }, 4000);
  }, [text]);

  if (!text) return;

  return (
    <div className="toast toast-center z-10">
      <div className={`alert ${isSuccess ? "alert-success" : "alert-info"}`}>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default Toast;
