import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import FullPageLoader from "./FullPageLoader";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import SigninWithGoogle from "./SigninWithGoogle";
import useAuthState from "../hooks/useAuthState";
import Header from "./Header";
import CreateNew from "../pages/CreateNew";
import Home from "../pages/Home";
import UseTemplate from "../pages/UseTemplate";
import MyCollections from "../pages/MyCollections";
import UseCollection from "../pages/UseCollection";
import EditTemplate from "../pages/EditTemplate";
import StaticPage from "../pages/Static";
import { useRemoteConfig } from "../hooks/useRemoteConfig";
import Footer from "./Footer";
import useInitialUserCollectionDataFetch from "../hooks/useInitialUserCollectionDataFetch";
import Gif from "./Gif";
import LandingPage from "../pages/LandingPage";
const Routing = () => {
  const state = useContext(AuthContext).state;
  const dispatch = useContext(AuthContext).dispatch;
  useAuthState();
  const { userCollectionFetched } = useInitialUserCollectionDataFetch();
  const { config, loading: configLoading } = useRemoteConfig();

  if (state.isAuthenticated === null || configLoading || (state.isAuthenticated && !userCollectionFetched)) {
    // Google Auth State not yet decided
    return (
      <div className="flex flex-col justify-center items-center w-full bg-red mt-4 prose">
        <Gif icon="noSearch" height="80px" width="80px" />
        <p>{config?.locale_app_loading_screen || "Loading..."}</p>
      </div>
    );
    // return <FullPageLoader />;
    // dispatch({ type: "LOADING_START", payload: "logginIn" });
  }

  if (state.isAuthenticated === false) {
    // render loginpage

    // return <h1>Sign in with google</h1>;\
    return (
      <Router>
        <LandingPage />
      </Router>
    );

    // return <SigninWithGoogle />;
  }

  // dispatch({ type: "LOADING_END", payload: "logginIn" });

  // return <p>fucl</p>;

  //   if (state.isAuthenticated) {
  //     return <SignoutWithGoogle />;
  //   }

  return (
    <>
      <Helmet>
        <title>{config?.locale_app_tagline}</title>
      </Helmet>

      <Router>
        <Header />
        <div className="mt-[100px] w-full flex justify-center p-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create-new" element={<CreateNew />} />
            <Route path="/genie/:templateId" element={<UseTemplate />} />
            <Route path="/edit-template/:templateId" element={<EditTemplate />} />
            <Route path="/collection/:collectionId" element={<UseCollection />} />
            <Route path="/page/:pageId" element={<StaticPage />} />
            <Route path="/my-collections/" element={<MyCollections />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
};

export default Routing;
