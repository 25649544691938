import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { AuthContext } from "../../context/AuthContext";
import Markdown from "react-markdown";
import useGetDataWithCache from "../../hooks/useGetDataWithCache";
import useAxios from "../../hooks/useAxios";
import { apiEndPoint } from "../../constants/api.constans";
import Modal from "../../components/Modal";
import DeleteTempalteConfirmationPopup from "../../components/DeleteTempalteConfirmationPopup";
import ShowPromptCopyModal from "../../components/ShowPromptCopyModal";
import useLog, { useApiErrorLog, useLogEffect } from "../../hooks/useAnalytics";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";
import Gif from "../../components/Gif";
import Alert from "../../components/Alert";

function CodeCopyBtn({ children }) {
  const [copyOk, setCopyOk] = useState(false);
  const { config } = useRemoteConfig();
  const iconColor = copyOk ? "#0af20a" : "#ddd";
  const icon = copyOk ? config.locale_response_code_snippet_copied_button : config.locale_response_code_snippet_copy_button;

  const handleClick = () => {
    navigator.clipboard.writeText(children.props.children);
    setCopyOk(true);
    setTimeout(() => {
      setCopyOk(false);
    }, 2000);
  };

  return (
    <button className="btn btn-primary" onClick={handleClick} style={{ cursor: "pointer", color: iconColor }}>
      {icon}
    </button>
  );
}

const Pre = ({ children }) => (
  <pre className="blog-pre">
    <CodeCopyBtn>{children}</CodeCopyBtn>
    {children}
  </pre>
);

// const CodeBlock = ({ code }) => (
//   <div className="mockup-code">
//     <pre>
//       <code>{code}</code>
//     </pre>
//   </div>
// );

// const ContentDisplay = ({ content }) => {
//   // Split content by backticks
//   const parts = content.split(/```/);

//   return (
//     <div>
//       {parts.map((part, index) => {
//         // Check if part is a code snippet
//         if (index % 2 === 1) {
//           return <CodeBlock key={index} code={part} />;
//         }
//         // Render normal text
//         return <p key={index}>{part}</p>;
//       })}
//     </div>
//   );
// };

const UseTemplate = () => {
  const { templateId } = useParams();
  // const state = useContext(AuthContext).state;
  // const [template, setTemplate] = useState({});
  const [userInputs, setUserInputs] = useState({});
  const dispatch = useContext(AuthContext).dispatch;
  const [conversation, setConversation] = useState([]);
  const [followUpText, setFollowupText] = useState("");
  const [queryMessage, setQueryMessage] = useState("");
  const [thinking, setThinking] = useState(false);
  const { getData, data: template } = useGetDataWithCache("templateDetails");
  const isPrivate = template.isPrivate;
  const state = useContext(AuthContext).state;
  const userId = state?.userData?.uid;
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const navigate = useNavigate();

  const [showCollectionManagementPopup, setCollectionManagementPopup] = useState(false);
  const [showCopyPromptModal, setShowCopyPromptModal] = useState(false);
  const userCredit = state?.userCredit;
  const creditBalance = userCredit?.creditBalance;
  const { config } = useRemoteConfig();

  const getTemplateDetails = () => {
    getData({ id: templateId });
  };
  useEffect(getTemplateDetails, [templateId]);

  const title = template?.metadata?.name;
  const description = template?.metadata?.description;
  const data = template?.data || [];
  const entryInputs = data.filter((d) => d.type === "entry") || [];

  useLogEffect({ event: "Template_View", title, templateId }, [title, templateId]);

  const onAskClick = () => {
    useLog({ event: "Template_Ask", title, templateId });
    const queryString = data.reduce((acc, d) => {
      if (d.type === "text") acc += d.value;
      else if (d.type === "entry") acc += ` ${userInputs[d.id]}` || "";

      return acc;
    }, "");
    if (creditBalance < 0) {
      setShowCopyPromptModal(queryString);
      return;
    }
    // setConversation([{ role: "user", content: queryString }]);
    setQueryMessage(queryString);
  };

  // Function to handle key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of new line in textarea
      onAskClick(); // Call the submit function
    }
  };

  const onFollowUp = () => {
    useLog({ event: "Template_Followup", title, templateId });
    // setConversation((c) => [...c, { role: "user", content: followUpText }]);
    setQueryMessage(followUpText);
    setFollowupText("");
  };
  const handleKeyPressFollowup = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of new line in textarea
      onFollowUp(); // Call the submit function
    }
  };
  const { post, get } = useAxios();

  const aiREsponseAPI = (bo) => {
    useLog({ event: "Getting_AI_Response_API_Started", query: JSON.stringify(bo) });
    return post(apiEndPoint + "/getResponseFromAI", bo).catch((err) => {
      useApiErrorLogg({ event: "Getting_AI_Response_API_Failed", error: err, query: JSON.stringify(bo) });
    });
  };

  const getUserCreditInformation = () => {
    useLog({ event: "Get_User_Credit_Information_API_Start" });
    return get(apiEndPoint + "/getUsersCredits")
      .then((res) => {})
      .catch((err) => {
        useApiErrorLog({ event: "Get_User_Credit_Information_API_Failed", error: err });
      });
  };

  window.getUserCreditInformation = getUserCreditInformation;

  useEffect(() => {
    if (!queryMessage) return;
    const updatedCoversation = [...conversation, { role: "user", content: queryMessage }];

    setConversation(updatedCoversation);
    setThinking(true);
    aiREsponseAPI(updatedCoversation)
      .then((resp) => {
        try {
          const { data } = resp;
          const res = data?.response;
          dispatch({ type: "SET_USER_CREDIT", payload: data?.usersCredit });

          const messageFromAssistant = res?.choices?.[0]?.message?.content || config.locale_conversation_error_from_ai;
          setConversation((conv) => {
            return [...conv, { role: "assistant", content: messageFromAssistant }];
          });
        } catch (errp) {
          setConversation((conv) => {
            return [...conv, { role: "assistant", content: config.locale_conversation_error_from_ai }];
          });
          useApiErrorLog({ event: "Getting_AI_Response_API_Success_Parsing_Failed", error: errp, query: JSON.stringify(resp) });
        }
      })
      .finally(() => {
        // setConversation((conv) => {
        //   return [...conv, { role: "assistant", content: config.locale_conversation_error_from_ai }];
        // });
        setThinking(false);
      });
  }, [queryMessage]);

  const onStartOver = () => {
    useLog({ event: "Template_Startover", title, templateId });

    setConversation([]);
    setFollowupText("");
    setQueryMessage("");
    setUserInputs({});
    setThinking(false);
  };

  const coversationOver = conversation.length >= config.maxConversationLength;

  if (!template) {
    return (
      <div className="flex flex-col justify-center items-center w-full flex-col gap-4 h-screen max-w-[800px] prose">
        <div className="flex justify-center w-full">
          <Gif icon="fetching" />
        </div>
        <p className="mt-4">{config.locale_loading_template_waiting_message}</p>
      </div>
    );
  }

  if (conversation.length) {
    return (
      <div className="flex flex-col prose max-w-[95vw] md:max-w-[50vw] w-full">
        <Helmet>
          <title>{`${title} - ${config.appname}`}</title>
        </Helmet>
        <h2 className="text-center">{title}</h2>
        <p className="text-center">{description}</p>
        <div className="users-inputs">
          {entryInputs.map((c) => (
            <div className="user-input">
              <h3 className="mt-0">{c.title}</h3>
              <p>{userInputs[c.id] || "-"}</p>
            </div>
          ))}
        </div>
        <hr className="mt-4 mb-4" />
        {conversation.map((conv, index) => {
          if (!index) return <></>;
          return (
            <div>
              <br />
              {/* <p>{conv.content}</p> */}
              <Markdown
                components={{
                  pre: Pre,
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <SyntaxHighlighter style={a11yDark} language={match[1]} PreTag="div" {...props}>
                        {String(children).replace(/\n$/, "")}
                      </SyntaxHighlighter>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {conv.content}
              </Markdown>
              {/* <ContentDisplay content={conv.content} /> */}
              <hr />
              <br />
            </div>
          );
        })}
        {thinking && (
          <div className="flex  justify-center items-center mb-4">
            <Gif height="50px" width="50px" icon="search" />
            <p className="text-center m-0 ml-4"> {config.locale_response_loading}</p>
            <Helmet>
              <title>{`${config.helmet_title_genie_thinking} - ${config.appname}`}</title>
            </Helmet>
            {/* <span className="loading loading-infinity loading-lg h-4"></span> */}
            {/* <progress className="progress w-56 m-auto mb-4"></progress> */}
          </div>
        )}
        <div className="flex mt-2 flex-col md:flex-row">
          {!coversationOver && creditBalance > 0 && !thinking && (
            <textarea
              onChange={(e) => setFollowupText(e.target.value)}
              onKeyPress={handleKeyPressFollowup} // Add key press handler here
              value={followUpText}
              className="textarea textarea-bordered w-full h-4"
            />
          )}
          {(coversationOver || creditBalance < 0) && (
            <button onClick={onStartOver} className="w-full btn btn-primary">
              {config.locale_template_start_over_button}
            </button>
          )}
          {!coversationOver && creditBalance > 0 && !thinking && (
            <button className="whitespace-nowrap md:ml-4 mt-4 md:mt-0 btn btn-primary" onClick={onFollowUp}>
              {config.locale_template_follow_up_button}
            </button>
          )}
        </div>
      </div>
    );
  }

  const onMakePrivate = () => {
    dispatch({ type: "LOADING_START", payload: "publicToPrivateTemplate" });
    post(apiEndPoint + "/publicToPrivateTemplate", template)
      .then(() => {
        getData({ id: templateId }, true);
        dispatch({ type: "SET_TOAST", payload: "publicToPrivateTemplateSuccess" });
      })
      .catch(() => {
        dispatch({ type: "SET_TOAST", payload: "publicToPrivateTemplateFailure" });
      })
      .finally(() => {
        dispatch({ type: "LOADING_END", payload: "publicToPrivateTemplate" });
      });
  };
  const onMakePublic = () => {
    dispatch({ type: "LOADING_START", payload: "privateToPublicTemplate" });
    post(apiEndPoint + "/privateToPublicTemplate", template)
      .then(() => {
        getData({ id: templateId }, true);
        dispatch({ type: "SET_TOAST", payload: "privateToPublicTemplateSuccess" });
      })
      .catch(() => {
        dispatch({ type: "SET_TOAST", payload: "privateToPublicTemplateFailure" });
      })
      .finally(() => {
        dispatch({ type: "LOADING_END", payload: "privateToPublicTemplate" });
      });
  };

  const onAddToCollection = () => {
    setCollectionManagementPopup(true);
  };

  const onDeleteClick = () => {
    setShowRemoveConfirmation(true);
  };

  const deleteTemplate = () => {
    dispatch({ type: "LOADING_START", payload: "deleteGenie" });
    post(apiEndPoint + "/deleteTemplateApi", template)
      .then(() => {
        getData({ id: templateId }, true);
        navigate("/create-new");
        dispatch({ type: "SET_TOAST", payload: "deleteGenieSuccess" });
      })
      .catch(() => {
        dispatch({ type: "SET_TOAST", payload: "deleteGenieFailed" });
      })
      .finally(() => {
        dispatch({ type: "LOADING_END", payload: "deleteGenie" });
      });
  };

  const onShare = async () => {
    try {
      useLog({ event: "Share_Tempalte", id: template.id, name: name });
      await navigator.clipboard.writeText(`${window.location.origin}/genie/${template.id}`);
      dispatch({ type: "SET_TOAST", payload: "shareLinkCopied" });
    } catch (err) {
      useApiErrorLog({ event: "Share_Template_Failed", error: err, id: template.id, name: name });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${title} - ${config.appname}`}</title>
      </Helmet>
      <div className="flex flex-col w-full max-w-[95vw] md:max-w-[50vw] prose">
        <div className="flex justify-center gap-4 flex-wrap mb-4">
          {isPrivate && template?.owner === userId && (
            <button className="btn btn-sm" onClick={onMakePublic}>
              {config.locale_template_make_public_button}
            </button>
          )}
          {!isPrivate && template?.owner === userId && (
            <button className="btn btn-sm" onClick={onMakePrivate}>
              {config.locale_template_make_private_button}
            </button>
          )}
          {template?.owner === userId && (
            <button className="btn btn-sm" onClick={onDeleteClick}>
              {config.locale_template_delete_button}
            </button>
          )}
          <button className="btn btn-sm" onClick={onAddToCollection}>
            {config.locale_template_add_to_collection}
          </button>
          {template?.owner === userId && (
            <button className="btn btn-sm" onClick={() => navigate(`/edit-template/${templateId}`)}>
              {config.locale_template_edit_button}
            </button>
          )}
          <button className="md:ml-auto flex items-center btn btn-link" onClick={onShare}>
            {" "}
            {config.locale_share_template_button_text}
            <svg className="ml-4" fill="white" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <title>share</title>
              <path d="M0 25.472q0 2.368 1.664 4.032t4.032 1.664h18.944q2.336 0 4-1.664t1.664-4.032v-8.192l-3.776 3.168v5.024q0 0.8-0.544 1.344t-1.344 0.576h-18.944q-0.8 0-1.344-0.576t-0.544-1.344v-18.944q0-0.768 0.544-1.344t1.344-0.544h9.472v-3.776h-9.472q-2.368 0-4.032 1.664t-1.664 4v18.944zM5.696 19.808q0 2.752 1.088 5.28 0.512-2.944 2.24-5.344t4.288-3.872 5.632-1.664v5.6l11.36-9.472-11.36-9.472v5.664q-2.688 0-5.152 1.056t-4.224 2.848-2.848 4.224-1.024 5.152zM32 22.080v0 0 0z"></path>
            </svg>
          </button>
        </div>
        {template?.isPrivate && <Alert title="" description={config.locale_private_template_alert_message} />}
        <h2 className="text-center">{title}</h2>
        <p className="text-center mt-0">{description || config.locale_no_description}</p>

        {entryInputs.map((entry) => (
          <label className="form-control" key={entry.id}>
            <div className="label">
              <span className="label-text">{entry.title}</span>
            </div>
            <textarea className="textarea textarea-bordered h-24" placeholder="" onChange={(e) => setUserInputs((ui) => ({ ...ui, [entry.id]: e.target.value }))}></textarea>
          </label>
        ))}
        <button onClick={onAskClick} className="mt-6 btn btn-primary">
          {config.locale_template_ask_button}
        </button>
        {showCollectionManagementPopup && <Modal onClose={() => setCollectionManagementPopup(false)} tempalateId={templateId} />}
        {showRemoveConfirmation && <DeleteTempalteConfirmationPopup confirmationCallback={deleteTemplate} onClose={() => setShowRemoveConfirmation(false)} />}
        {showCopyPromptModal && <ShowPromptCopyModal prompt={showCopyPromptModal} onClose={() => setShowCopyPromptModal(false)} />}
      </div>
    </>
  );
};

export default UseTemplate;
