import { useContext, useState } from "react";
import useAxios from "./useAxios";
import { getTemplateDetails } from "../firebase/databaseOperations/getTemplateDetails";
import { AuthContext } from "../context/AuthContext";
import { getAllTemplatesOfUser } from "../firebase/databaseOperations/getAllTemplatesOfUser";
import { getCollectionDetails } from "../firebase/databaseOperations/getCollectionDetails";

let cache = {};
let lastUpdateTime = {};
let promiseCache = {};
const useGetDataWithCache = (source) => {
  const [data, setData] = useState("");
  const state = useContext(AuthContext).state;

  const { post } = useAxios();

  const cacheTTLs = {
    templateDetails: 5000000,
    usersColellections: 500000000,
    collectionDetails: 50000000,
  };
  const getFunctions = {
    templateDetails: ({ id }) => {
      return getTemplateDetails({ userData: state.userData, id });
    },
    usersColellections: () => {
      return getAllTemplatesOfUser({ uid: state.userData.uid });
    },
    collectionDetails: ({ id } = {}) => {
      return getCollectionDetails({ id });
    },
  };
  const getFunction = getFunctions[source];

  const getData = ({ id, ...otherProps } = {}, igonreCache) => {
    if (igonreCache) setData("");
    if (cache?.[source]?.[id] && new Date().getTime() < lastUpdateTime?.[source]?.[id] + cacheTTLs?.[source] && !igonreCache) {
      // Serve from cache.
      
      setData(cache?.[source]?.[id]);
      return Promise.resolve(cache?.[source]?.[id]);
    }

    if (promiseCache?.[source]?.[id]) {
      return promiseCache[source][id];
    }
    let pp = getFunction({ id, ...otherProps })
      .then((res) => {
        cache = {
          ...cache,
          [source]: {
            ...(cache[source] || {}),
            [id]: res,
          },
        };
        lastUpdateTime = {
          ...lastUpdateTime,
          [source]: {
            ...(lastUpdateTime[source] || {}),
            [id]: new Date().getTime(),
          },
        };
        

        setData(res || "error");
        promiseCache[source][id] = undefined;
        return res;
      })
      .catch((err) => {
        setData("error");
        promiseCache[source][id] = undefined;
        return Promise.reject(err);
      });

    if (!promiseCache[source]) promiseCache[source] = {};
    promiseCache[source][id] = pp;
    return pp;
  };

  const clearCache = (id) => {
    if (cache?.[source]?.[id]) {
      cache[source][id] = undefined;
    }
    // setData("")
  };

  const clearAllCache = () => {
    cache[source] = {};
    setData("");
  };

  return {
    getData,
    data,
    clearAllCache,
    clearCache,
  };
};

export default useGetDataWithCache;

// known yesterday,
// Hospital
// why adakm on wednesday.
// aranu varna ulle
// evdanu adakunne
