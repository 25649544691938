import { useSearchParams } from "react-router-dom";

const useMyNavigation = () => {
  const [sP, setSearchParams] = useSearchParams();
  // Convert URLSearchParams to a plain object
  const searchParams = Object.fromEntries(sP.entries());

  const addSearchParam = (newParams, replace = false) => {
    // Create a new URLSearchParams object to manipulate
    const params = new URLSearchParams(searchParams);

    for (const p of Object.keys(newParams)) {
      // Check if the value is empty
      if (newParams[p] === "") {
        // Remove the parameter if the value is empty
        params.delete(p);
      } else {
        // Set the new search parameter
        params.set(p, newParams[p]);
      }
    }

    // Update the URL with push or replace
    if (replace) {
      setSearchParams(params, { replace: true });
    } else {
      setSearchParams(params);
    }
  };

  return { searchParams, addSearchParam };
};

export default useMyNavigation;
