import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import TemplateCardWrapper from "../../components/TemplateCard";
import useAccessToken from "../../hooks/useAccessToken";
import TabPage from "../../components/TabPage";
import useGetDataWithCache from "../../hooks/useGetDataWithCache";
import SearchComponent from "../../components/Search";
import MainLogo from "../../components/MainLogo";

const TabComponent = ({ id, name, selectedTab, currentTabName }) => {
  const { getData, data } = useGetDataWithCache("collectionDetails");

  useEffect(() => {
    selectedTab && getData({ id: selectedTab });
  }, [selectedTab]);

  if (!data?.templates) {
    return (
      <div className="flex flex-wrap">
        <div className="skeleton h-32 w-full max-w-[295px] m-4"></div>
        <div className="skeleton h-32 w-full max-w-[295px] m-4"></div>
      </div>
    );
  }

  return (
    <>
      {/* <h3>{currentTabName}</h3> */}
      {data?.templates ? <TemplateCardWrapper templates={data?.templates || []} /> : <></>}
    </>
  );
};

const Home = () => {
  const state = useContext(AuthContext).state;
  const [myTemplates, setMyTemplates] = useState([]);
  const getAccessToken = useAccessToken();
  const { getData, data } = useGetDataWithCache("usersColellections");

  useEffect(() => {
    getData();
    // getAllTemplatesOfUser({ userData: state.userData })
    //   .then((res) => {
    //     setMyTemplates(res || []);
    //
    //   })
    //   .catch((err) => {
    //
    //   });
  }, []);

  const [showColelctionsTab, setShowCollectionsTab] = useState(true);

  if (!data) return <></>;

  const searchResultCallback = (searchResultStatus) => {
    setShowCollectionsTab(!searchResultStatus);
  };

  return (
    <div className="w-full  max-w-[1000px] mx-auto mt-[10vh]">
      <MainLogo />
      <SearchComponent searchResultCallback={searchResultCallback} />
      {showColelctionsTab && Boolean(data?.collections?.length) && (
        <TabPage data={data.collections || []}>
          <TabComponent />
        </TabPage>
      )}
    </div>
  );
};

export default Home;
