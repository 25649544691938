// import LocalStorage from "@/utils/localStorage"; // Uncomment if needed
import React, { createContext, useReducer, ReactNode, Dispatch } from "react";
import { removeElementAtIndex } from "../utils/utils";

// Define types for the state and actions
interface AuthState {
  isAuthenticated: boolean | null; // Changed from undefined to null for clarity
  userData: { uid: string };
  userCredit: {};
}

interface AuthAction {
  type: "IS_AUTHENTICATED" | "SET_USER_DATA";
  payload: boolean | null | object; // Payload can be boolean or null (removed string type)
}

// Initial state
const initialState: AuthState = {
  isAuthenticated: null, // Set initial state to null instead of undefined
  userData: { uid: "" },
  userCredit: JSON.parse(localStorage.getItem("creditLeft") || "{}"),
  mainLogoVisible: false,
  loadingState: [],
  toast: "",
};

// Create the context
export const AuthContext = createContext<{
  state: AuthState;
  dispatch: Dispatch<AuthAction>; // Use Dispatch type from React
}>({ state: initialState, dispatch: () => undefined });

// Define the reducer function
const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "IS_AUTHENTICATED":
      return { ...state, isAuthenticated: action.payload };
    case "SET_USER_DATA":
      return { ...state, userData: action.payload };
    case "SET_MAIN_LOGO_VISIBILITY":
      return { ...state, mainLogoVisible: action.payload };
    case "SET_TOAST":
      return { ...state, toast: action.payload };
    case "LOADING_START":
      if (state.loadingState.includes(action.payload)) {
        return state;
      }
      return { ...state, loadingState: [...state.loadingState, action.payload] };
    case "LOADING_END":
      const indexOfState = state.loadingState.indexOf(action.payload);
      if (indexOfState === -1) {
        return state;
      }
      return { ...state, loadingState: removeElementAtIndex(state.loadingState, indexOfState) };
    case "SET_USER_CREDIT":
      const _payload = { ...action.payload };
      _payload.creditExpired = _payload?.creditBalance < 0;
      localStorage.setItem("creditLeft", JSON.stringify(_payload || {}));
      return { ...state, userCredit: _payload };

    default:
      return state; // Ensure all paths return a value
  }
};

// Create a provider component
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};
