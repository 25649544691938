import { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import useAxios from "./useAxios";
import { apiEndPoint } from "../constants/api.constans";

const useCredits = () => {
  const state = useContext(AuthContext).state;
  const dispatch = useContext(AuthContext).dispatch;
  const isAuthenticated = state.isAuthenticated;
  const userCredit = state.userCredit;

  const { post, get } = useAxios();

  const getUserCreditInformation = () => {
    return get(apiEndPoint + "/getUsersCredits");
  };

  const getAndSetCreditInfo = () => {
    getUserCreditInformation().then(({ data = {} } = {}) => {
      dispatch({ type: "SET_USER_CREDIT", payload: data });
    });
  };

  const getUserCreditInfo = () => {
    if (!isAuthenticated) return;
    getAndSetCreditInfo();
  };

  useEffect(getUserCreditInfo, [isAuthenticated]);

  const timer = useRef();
  const autoCreditStatusUpdate = () => {
    if (!userCredit?.nextCreditRefresh || !userCredit?.currentTime) {
      return () => {
        clearTimeout(timer.current);
      };
    }
    const timerUntilNextRefresh = userCredit.nextCreditRefresh - userCredit.currentTime;
    timer.current = setTimeout(() => {
      getAndSetCreditInfo();
    }, timerUntilNextRefresh);
    return () => {
      clearTimeout(timer.current);
    };
  };

  useEffect(autoCreditStatusUpdate, [userCredit]);
};

export default useCredits;
