import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

function copyToClipboard(inputString) {
  if (!navigator.clipboard) {
    console.error("Clipboard API not supported");
    return;
  }

  return navigator.clipboard
    .writeText(inputString)
    .then(() => {})
    .catch((err) => console.error("Failed to copy: ", err));
}

const ShowPromptCopyModal = ({ onClose = () => {}, prompt = "" }) => {
  const dispatch = useContext(AuthContext).dispatch;
  const loadingState = useContext(AuthContext).state.loadingState;
  const { config } = useRemoteConfig();

  useEffect(() => {
    if (loadingState?.length) return;
    document.getElementById("my_modal_3").showModal();
    // getData();
  }, [loadingState?.length]);

  if (loadingState?.length) return <></>;

  const copyPropmptToClipboard = () => {
    copyToClipboard(prompt).then(() => {
      dispatch({ type: "SET_TOAST", payload: "promptCopied" });
    });
  };

  return (
    <dialog id="my_modal_3" className="modal" onClose={onClose}>
      <div className="modal-box">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button onClick={onClose} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <h2 className="font-bold text-lg">{config.locale_no_credit_left_title}</h2>
        <p>{config.locale_no_credit_left_description}</p>
        <button onClick={copyPropmptToClipboard}> {config.locale_no_credit_left_copy_prompt_button}</button>
      </div>
    </dialog>
  );
};

export default ShowPromptCopyModal;
