import { auth } from "../firebase/init";

const useAccessToken = () => {
  const getAccessToken = () => {
    return auth?.currentUser?.accessToken;
  };

  return getAccessToken;
};

export default useAccessToken;
