import { useNavigate } from "react-router-dom";
import Gif from "../Gif";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";

const TemplateCard = ({ template = {}, onRemoveClick }) => {
  const name = template.name || template?.metadata?.name;
  const description = template.description || template?.metadata?.description;
  const navigate = useNavigate();

  const handleClick = () => {
    let typeFromTemplate = template.type;
    if (typeFromTemplate === "template") typeFromTemplate = "genie";
    navigate(`/${typeFromTemplate || "genie"}/${template.id || template?.metadata?.id}`);
  };
  return (
    <div className="card bg-base-100 w-full md:w-[48%] shadow-xl transition-transform transform hover:scale-105 p-0" onClick={handleClick}>
      <div className="card-body p-3 prose pr-4 pl-4">
        <h3 className="card-title m-0">{name}</h3>
        {<p className="line-clamp-3 flex-grow-0">{description || "No Description"}</p>}
        {/* Uncomment to add more content */}
        {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
        <div className="card-actions justify-end">
          {/* Uncomment to add button */}
          {/* <button className="btn btn-primary">Buy Now</button> */}
        </div>
        {onRemoveClick && (
          <button
            className="btn btn-sm"
            onClick={(e) => {
              onRemoveClick(template?.metadata?.id);
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Remove From Collection
          </button>
        )}
      </div>
    </div>
  );
};

const TemplateCardWrapper = ({ templates = [], onRemoveClick }) => {
  const { config } = useRemoteConfig();
  return (
    <div className="flex flex-wrap justify-center gap-3 p-4 mt-8 w-full">
      {!templates?.length && (
        <div className="flex flex-col justify-center items-center w-full bg-red mt-4 prose">
          <Gif icon="noSearch" height="80px" width="80px" />
          <p>{config.locale_no_genie_in_collection}</p>
        </div>
      )}
      {templates.map((t, index) => (
        <TemplateCard key={index} template={t} onRemoveClick={onRemoveClick} />
      ))}
    </div>
  );
};

export default TemplateCardWrapper;
