import "./App.css";
import FullPageLoader from "./components/FullPageLoader";
import Routing from "./components/Routing";
import Toast from "./components/Toast";
import { AuthProvider } from "./context/AuthContext";
import "./firebase/init";
import { useAxiosInterceptor } from "./hooks/useAxios";
import { RemoteConfigProvider } from "./hooks/useRemoteConfig";
import "./markdown.css";

function App() {
  useAxiosInterceptor();
  return (
    // <React.StrictMode>
    <RemoteConfigProvider>
      <AuthProvider>
        <FullPageLoader />
        <Toast />
        <Routing />
      </AuthProvider>
    </RemoteConfigProvider>
    // </React.StrictMode>
  );
}

export default App;
