import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/init";
import { useEffect } from "react";

function useLog({ event, ...otherParams }) {
  logEvent(analytics, event, otherParams);
}

export const useLogEffect = (allEventProperties, mandatoryParams) => {
  const logOnMount = () => {
    // Check if all mandatory parameters are truthy
    const readyToSendLog = !mandatoryParams?.length || mandatoryParams.every((param) => Boolean(param));

    // If all mandatory parameters are truthy, call trackLog
    if (readyToSendLog) {
      
      useLog(allEventProperties);
    }
  };

  useEffect(logOnMount, [...mandatoryParams]);
};

export default useLog;

export const useApiErrorLog = ({ error, ...otherEventProperties }) => {
  useLog({ ...otherEventProperties, type: "Error", errorData: error?.response?.data, errorStatus: error?.response?.status, errorHeaders: error?.response?.headers });
};

// Optional setting of user defined properties.
// analytics.setUserProperties({ favorite_game: 'chess' });
