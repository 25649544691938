import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useGetDataWithCache from "../../hooks/useGetDataWithCache";
import TemplateCardWrapper from "../../components/TemplateCard";
import useAxios from "../../hooks/useAxios";
import { apiEndPoint } from "../../constants/api.constans";
import RemoveTemplateFromCollectionModal from "../../components/RemoveTemplateFromCollectionModal";
import { AuthContext } from "../../context/AuthContext";
import DeleteCollectionConfirmationPopup from "../../components/DeleteCollectionConfirmationPopup";
import useLog, { useApiErrorLog, useLogEffect } from "../../hooks/useAnalytics";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";
import Gif from "../../components/Gif";
import useMyNavigation from "../../hooks/useMyNavigation";
import { scrollToChildVertically } from "../../utils/utils";
import Alert from "../../components/Alert";
import InputWithClose from "../../components/InputWithClose";

const MyCollections = () => {
  const { getData, data } = useGetDataWithCache("usersColellections");
  const { getData: getCollectionDetails, data: collectionDetails } = useGetDataWithCache("collectionDetails");
  const state = useContext(AuthContext).state;
  const userId = state?.userData?.uid;

  const { searchParams, addSearchParam } = useMyNavigation();
  const selectedCollection = searchParams?.collection || "";

  const setSelectedCollection = (cid) => {
    addSearchParam({ collection: cid });
  };
  const [editName, setEditName] = useState(false);
  const [name, setName] = useState("");
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [createCollectionFlow, setCreateCollectionFlow] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");

  const { post, put } = useAxios();
  const { config } = useRemoteConfig();
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useContext(AuthContext).dispatch;

  let collectionDetailsName = collectionDetails?.name;
  if (collectionDetails?.type === "ownPublicTemplate") collectionDetailsName = config.locale_my_public_template_name;
  else if (collectionDetails?.type === "ownPrivateTemplate") collectionDetailsName = config.locale_my_private_template_name;
  else if (collectionDetails?.type === "ownfavouriteTemplate") collectionDetailsName = config.locale_my_favourite_template_name;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    scrollToChildVertically("collection-table", `collection-item-${selectedCollection}`);
  }, [selectedCollection]);

  useEffect(() => {
    if (!selectedCollection) return;
    getCollectionDetails({ id: selectedCollection });
    setEditName(false);
  }, [selectedCollection]);
  useLogEffect({ event: "MyCollection_View", id: collectionDetails?.id, name: collectionDetails?.name }, [collectionDetails?.id, collectionDetails?.name]);

  if (!data?.collections?.length)
    return (
      <div className="flex flex-col justify-center items-center w-full flex-col gap-4 h-screen max-w-[800px] prose">
        <div className="flex justify-center w-full">
          <Gif icon="fetching" />
        </div>
        <p className="mt-4">Fetching yout collections...</p>
      </div>
    );
  if (!selectedCollection || !data?.collections?.find((c) => c.id === selectedCollection)) {
    setSelectedCollection(data?.collections?.[0]?.id);
  }

  const selectedCollectionObject = data?.collections?.find((c) => c.id === selectedCollection);

  const onNameUpdate = () => {
    useLog({ event: "Collection_Name_Edit_Submit" });
    dispatch({ type: "LOADING_START", payload: "updatingCollection" });

    put(apiEndPoint + "/updateCollectionApi", { id: collectionDetails.id, name: name })
      .then(() => {
        setEditName(false);
        setName("");
        getCollectionDetails({ id: selectedCollection }, true)
          .then(() => {
            dispatch({ type: "LOADING_END", payload: "updatingCollection" });
            dispatch({ type: "SET_TOAST", payload: "updatingCollectionSuccess" });
            // setTimeout(() => window.location.reload(), 1000);
          })
          .catch(() => {
            dispatch({ type: "LOADING_END", payload: "updatingCollection" });
            dispatch({ type: "SET_TOAST", payload: "updatingCollectionFailed" });
          });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_END", payload: "updatingCollection" });
        dispatch({ type: "SET_TOAST", payload: "updatingCollectionFailed" });
        useApiErrorLog({ event: "publishPrivateTemplateApi_Failed", error: err, id: collectionDetails.id, name: name });
      });
    // post(apiEndPoint + '/')
  };

  const onRemoveClick = (templateIdToRemove) => {
    useLog({ event: "Remove_Tempalte_From_Collection_Popup_Shown" });
    setShowRemoveConfirmation(templateIdToRemove);
  };

  const removetempalteFromCollection = () => {
    setShowRemoveConfirmation(false);
    dispatch({ type: "LOADING_START", payload: "removingRecipeFromCollection" });
    useLog({ event: "Remove_Tempalte_From_Collection_API_Start" });
    post(apiEndPoint + "/removeTemplateFromCollection", { collectionId: selectedCollection, tempalateId: showRemoveConfirmation })
      .then(() => {
        getCollectionDetails({ id: selectedCollection }, true);
        dispatch({ type: "LOADING_END", payload: "removingRecipeFromCollection" });
        dispatch({ type: "SET_TOAST", payload: "removingRecipeFromCollectionSuccess" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_END", payload: "removingRecipeFromCollection" });
        dispatch({ type: "SET_TOAST", payload: "removingRecipeFromCollectionFailure" });
        useApiErrorLog({ event: "Remove_Tempalte_From_Collection_API_Failed", error: err, id: collectionDetails.id, name: name });
      });
    setShowRemoveConfirmation(false);
  };

  const handleCollectionStatusChange = () => {
    if (collectionDetails?.isPrivate) {
      useLog({ event: "Change_Collection_Status_To_Public_API_Start", id: collectionDetails.id, name: name });
      dispatch({ type: "LOADING_START", payload: "publishingCollection" });

      post(apiEndPoint + "/privateToPublicCollection", { id: selectedCollection })
        .then(() => {
          getCollectionDetails({ id: selectedCollection }, true);
          dispatch({ type: "LOADING_END", payload: "publishingCollection" });
          dispatch({ type: "SET_TOAST", payload: "publishingCollectionSuccess" });
        })
        .catch((err) => {
          dispatch({ type: "LOADING_END", payload: "publishingCollection" });
          dispatch({ type: "SET_TOAST", payload: "publishingCollectionFailed" });

          useApiErrorLog({ event: "Change_Collection_Status_To_Public_API_Failed", error: err, id: collectionDetails.id, name: name });
        });
    } else {
      useLog({ event: "Change_Collection_Status_To_Private_API_Start", id: selectedCollection });
      dispatch({ type: "LOADING_START", payload: "makeCollectionPrivate" });

      post(apiEndPoint + "/publicToPrivateCollection", { id: selectedCollection })
        .then(() => {
          getCollectionDetails({ id: selectedCollection }, true);
          dispatch({ type: "LOADING_END", payload: "makeCollectionPrivate" });
          dispatch({ type: "SET_TOAST", payload: "makeCollectionPrivateSuccess" });
        })
        .catch((err) => {
          dispatch({ type: "LOADING_END", payload: "makeCollectionPrivate" });
          dispatch({ type: "SET_TOAST", payload: "makeCollectionPrivateFailed" });
          useApiErrorLog({ event: "Change_Collection_Status_To_Private_API_Failed", error: err, id: collectionDetails.id, name: name });
        });
    }
  };

  const deleteCollection = () => {
    dispatch({ type: "LOADING_START", payload: "deleteCollection" });
    useLog({ event: "Delete_Collection_API_Start", id: collectionDetails.id, name: name });
    setShowDeleteConfirmation(false);
    post(apiEndPoint + "/deleteCollectionApi", collectionDetails)
      .then(() => {
        dispatch({ type: "SET_TOAST", payload: "deleteCollectionSuccess" });
        dispatch({ type: "LOADING_END", payload: "deleteCollection" });
        getCollectionDetails({ id: selectedCollection }, true).then(() => {});
      })
      .catch((err) => {
        dispatch({ type: "SET_TOAST", payload: "deleteCollectionFailed" });
        dispatch({ type: "LOADING_END", payload: "deleteCollection" });
        useApiErrorLog({ event: "Delete_Collection_API_Failed", error: err, id: collectionDetails.id, name: name });
      });
  };

  const handleRemoveFromMyCollection = () => {
    dispatch({ type: "LOADING_START", payload: "removeCollectionFromMyCollection" });

    useLog({ event: "Remove_Collection_From_MyCollection_API_Start", id: collectionDetails.id, name: name });
    post(apiEndPoint + "/removeFromMyCollectionApi", collectionDetails)
      .then(() => {
        setSelectedCollection(data?.collections?.[0]?.id);
        getData();
        dispatch({ type: "LOADING_END", payload: "removeCollectionFromMyCollection" });
        dispatch({ type: "SET_TOAST", payload: "removeCollectionFromMyCollectionSuccess" });
      })
      .catch((err) => {
        useApiErrorLog({ event: "Remove_Collection_From_MyCollection_API_Failed", error: err, id: collectionDetails.id, name: name });
        dispatch({ type: "LOADING_END", payload: "removeCollectionFromMyCollection" });
        dispatch({ type: "SET_TOAST", payload: "removeCollectionFromMyCollectionFailed" });
      });
  };

  const onShare = async () => {
    try {
      useLog({ event: "Share_Collection", id: collectionDetails.id, name: name });
      await navigator.clipboard.writeText(`${window.location.origin}/collection/${selectedCollection}`);
      dispatch({ type: "SET_TOAST", payload: "shareLinkCopied" });
    } catch (err) {
      useApiErrorLog({ event: "Share_Collection_Failed", error: err, id: collectionDetails.id, name: name });
    }
  };

  const toggleCollapse = () => {
    setIsOpen(!isOpen); // Toggle the collapse state
  };

  const createNewCollection = () => {
    dispatch({ type: "LOADING_START", payload: "createNewCollection" });
    post(apiEndPoint + "/createCollectionApi", { name: newCollectionName })
      .then((data) => {
        setCreateCollectionFlow(false);
        setNewCollectionName("");
        getData({}, true).finally(() => {
          setSelectedCollection(data?.data?.id);

          dispatch({ type: "LOADING_END", payload: "createNewCollection" });
          dispatch({ type: "SET_TOAST", payload: "collectionCreationSuccess" });
        });
      })
      .catch(() => {
        dispatch({ type: "SET_TOAST", payload: "collectionCreationFailed" });
        dispatch({ type: "LOADING_END", payload: "createNewCollection" });
      });
  };

  function uncheckCheckbox() {
    const checkbox = document.getElementById("myCheckbox");
    checkbox.checked = false; // Uncheck the checkbox
  }

  return (
    <div className="flex w-full  max-w-[1000px] mx-auto mt-4 prose flex-col md:flex-row">
      <Helmet>
        <title>{`${config.helmet_title_my_collections} - ${config.appname}`}</title>
      </Helmet>
      {/* Dropdown for mobile devices - START */}
      <h3 className="block md:hidden">{config.locale_my_collection_title}</h3>
      {!createCollectionFlow && (
        <button className="block md:hidden btn btn-success w-full" onClick={() => setCreateCollectionFlow(true)}>
          {config.locale_create_collection_popup_create_button_text}
        </button>
      )}
      {createCollectionFlow && (
        <div className="block md:hidden flex flex-col mt-4 ">
          <InputWithClose
            alwaysShowClose
            handleClear={() => {
              setCreateCollectionFlow(false);
              setNewCollectionName("");
            }}
            query={newCollectionName}
            setQuery={(value) => {
              setNewCollectionName(value);
            }}
            wrapperClassName="w-full max-w-[100vw]"
            placeholder={config.locale_my_collection_page_new_collection_name_placeholder}
          />
          <button className="btn btn-success mt-3 " disabled={!newCollectionName} onClick={createNewCollection}>
            {config.locale_my_collection_page_create_collection_button}
          </button>
        </div>
      )}
      <div className="collapse bg-base-200 w-full md:hidden mb-4 mt-4" onClick={toggleCollapse}>
        <input id="myCheckbox" type="checkbox" />
        <div className="collapse-title text-xl font-medium">
          {collectionDetailsName}
          {"  "} {isOpen ? "▲" : "▼"}
        </div>
        <div className="collapse-content flex items-center space-x-2">
          <table className="table">
            <tbody>
              {data?.collections.map((c) => {
                let cName = c?.name;
                if (c?.type === "ownPublicTemplate") cName = config.locale_my_public_template_name;
                else if (c?.type === "ownPrivateTemplate") cName = config.locale_my_private_template_name;
                else if (c?.type === "ownfavouriteTemplate") cName = config.locale_my_favourite_template_name;

                return (
                  <tr
                    className="hover"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCollection(c.id);
                      uncheckCheckbox();
                      toggleCollapse();
                    }}
                  >
                    <td className="text-center">{cName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Dropdown for mobile devices - END */}
      <div className="menu bg-base-200 rounded-box hidden md:block m-0 w-full max-w-[300px] h-fit">
        <h3 className="whitespace-nowrap m-0 mr-4 ml-4 mt-4 mb-4 text-center">{config.locale_my_collection_title}</h3>

        {!createCollectionFlow && (
          <button className=" btn btn-success w-full" onClick={() => setCreateCollectionFlow(true)}>
            {config.locale_create_collection_popup_create_button_text}
          </button>
        )}
        {createCollectionFlow && (
          <div className="flex flex-col mt-4 ">
            <InputWithClose
              alwaysShowClose
              handleClear={() => {
                setCreateCollectionFlow(false);
                setNewCollectionName("");
              }}
              query={newCollectionName}
              setQuery={(value) => {
                setNewCollectionName(value);
              }}
              wrapperClassName="w-full"
              placeholder={config.locale_my_collection_page_new_collection_name_placeholder}
            />
            <button className="btn btn-success mt-3 " disabled={!newCollectionName} onClick={createNewCollection}>
              {config.locale_my_collection_page_create_collection_button}
            </button>
          </div>
        )}

        <div id="collection-table" className="max-h-[50vh] overflow-scroll custom-scrollbar overflow-y-scroll">
          <table className="table">
            <tbody className="flex flex-col">
              {data?.collections.map((c) => {
                let cName = c?.name;
                if (c?.type === "ownPublicTemplate") cName = config.locale_my_public_template_name;
                else if (c?.type === "ownPrivateTemplate") cName = config.locale_my_private_template_name;
                else if (c?.type === "ownfavouriteTemplate") cName = config.locale_my_favourite_template_name;
                return (
                  <tr id={`collection-item-${c.id}`} className={c.id === selectedCollection ? "btn btn-primary mb-1" : "btn mb-1"} onClick={() => setSelectedCollection(c.id)}>
                    <td className="text-center">{`${cName} ${c.id === selectedCollection ? " ➤" : ""}`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="bg-base-200 rounded-box p-4 md:ml-4 w-full">
        <div className="flex flex-col md:flex-row items-center mb-4 gap-2">
          <div className="flex items-center flex-col md:flex-row w-full">
            {editName ? (
              <InputWithClose alwaysShowClose handleClear={() => setEditName(false)} query={name} setQuery={(value) => setName(value)} wrapperClassName="md:mr-4 w-full md:w-auto" />
            ) : (
              // <input value={name} onChange={(e) => setName(e.target.value)} className="input input-bordered input-success md:mr-4 w-full md:w-auto" />
              <h3 className="m-0 md:mr-4 whitespace-nowrap">{collectionDetailsName}</h3>
            )}
            {!collectionDetails?.isNotPublishable &&
              collectionDetails?.owner === userId &&
              (!editName ? (
                <button
                  className="btn btn-ghost"
                  onClick={() => {
                    useLog({ event: "Collection_Name_Edit_Start" });
                    setEditName(true);
                    setName(collectionDetails.name);
                  }}
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232a4 4 0 00-5.464 0L3 12.414V17h4.586l6.768-6.768a4 4 0 000-5.464z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.5 3.5L20.5 7.5M3 17l1.5-1.5" />
                </svg> */}
                  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path
                      d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              ) : (
                <button className="btn btn-neutral btn-success mt-4 md:mt-0 w-full md:w-auto" onClick={() => onNameUpdate()}>
                  {config.locale_update_collection_button_text}
                </button>
              ))}
          </div>
          <div className="w-full flex flex-wrap gap-2 mt-4 md:mt-0">
            {collectionDetails?.owner === userId && !collectionDetails?.isNotPublishable && (
              <button className="btn btn-neutral btn-sm" onClick={handleCollectionStatusChange}>
                {collectionDetails?.isPrivate ? config.locale_publish_collection_button_text : config.locale_make_collection_private_button_text}
              </button>
            )}
            {collectionDetails?.owner === userId && !collectionDetails?.isNotPublishable && (
              <button className="btn btn-neutral btn-sm" onClick={() => setShowDeleteConfirmation(true)}>
                {config.locale_delete_collection_button_text}
              </button>
            )}
            {collectionDetails?.id && collectionDetails?.owner !== userId && (
              <button className="btn btn-neutral btn-sm" onClick={handleRemoveFromMyCollection}>
                {config.locale_remove_template_from_collection_button_text}
              </button>
            )}
            {!collectionDetails?.isPrivate && (
              <button className="md:ml-auto flex items-center btn btn-link btn-sm" onClick={onShare}>
                {config.locale_share_collection_button_text}
                <svg className="ml-4 h-4 w-4" fill="white" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <title>share</title>
                  <path d="M0 25.472q0 2.368 1.664 4.032t4.032 1.664h18.944q2.336 0 4-1.664t1.664-4.032v-8.192l-3.776 3.168v5.024q0 0.8-0.544 1.344t-1.344 0.576h-18.944q-0.8 0-1.344-0.576t-0.544-1.344v-18.944q0-0.768 0.544-1.344t1.344-0.544h9.472v-3.776h-9.472q-2.368 0-4.032 1.664t-1.664 4v18.944zM5.696 19.808q0 2.752 1.088 5.28 0.512-2.944 2.24-5.344t4.288-3.872 5.632-1.664v5.6l11.36-9.472-11.36-9.472v5.664q-2.688 0-5.152 1.056t-4.224 2.848-2.848 4.224-1.024 5.152zM32 22.080v0 0 0z"></path>
                </svg>
              </button>
            )}
          </div>
        </div>
        {collectionDetails?.isPrivate && !collectionDetails?.isNotPublishable && <Alert title="" description={config.locale_private_collection_alert} />}
        {collectionDetails === "error" && <h3 className="text-center">Not Available</h3>}
        {collectionDetails?.id && (
          <TemplateCardWrapper templates={collectionDetails?.templates || []} onRemoveClick={!selectedCollectionObject?.isNotPublishable && collectionDetails?.owner === userId && onRemoveClick} />
        )}
      </div>
      {showRemoveConfirmation && <RemoveTemplateFromCollectionModal confirmationCallback={removetempalteFromCollection} onClose={() => setShowRemoveConfirmation(false)} />}
      {showDeleteConfirmation && <DeleteCollectionConfirmationPopup confirmationCallback={deleteCollection} onClose={() => setShowDeleteConfirmation(false)} />}
    </div>
  );
};

export default MyCollections;
