import React, { useState, useEffect, useRef } from "react";
import { algoliasearch } from "algoliasearch";
import TemplateCardWrapper from "../TemplateCard";
import useMyNavigation from "../../hooks/useMyNavigation";
import Gif from "../Gif";
import InputWithClose from "../InputWithClose";
import { useRemoteConfig } from "../../hooks/useRemoteConfig";
import { algoliaApiKey, algoliaAppId, algoliaindexName } from "../../app-config";

const client = algoliasearch(algoliaAppId, algoliaApiKey);

const transformSearchResult = (rawResult = []) => {
  return rawResult.map((result) => {
    const _return = {};
    if (result.path.includes("templates/")) {
      // Its a tempalte
      _return.isPrivate = result.isPrivate;
      _return.description = result.metadata.description;
      _return.name = result.metadata.name;
      _return.id = result.metadata.id;
      _return.owner = result.owner;
      _return.type = "template";
    } else {
      // Its a collection
      _return.isPrivate = result.isPrivate;
      _return.description = result.description;
      _return.name = result.name;
      _return.id = result.id;
      _return.owner = result.owner;
      _return.type = "collection";
    }
    return _return;
  });
};

const SearchComponent = ({ searchResultCallback = () => {} }) => {
  const { searchParams, addSearchParam } = useMyNavigation();
  const [query, setQuery] = useState(searchParams.search);
  const [results, setResults] = useState([]);
  const [showNoSearchError, setShowNoSearchError] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const queryRef = useRef(query);
  queryRef.current = query;

  const timer = useRef();
  // Perform search
  const performSearch = async (searchQuery) => {
    setShowNoSearchError(false);
    if (searchQuery) {
      clearTimeout(timer.current);
      setSearchLoading(true);
      setResults([]);
      timer.current = setTimeout(async () => {
        if (!queryRef.current) return;
        addSearchParam({ search: searchQuery });
        try {
          const { hits } = await client.searchSingleIndex({
            indexName: algoliaindexName,
            searchParams: { query: searchQuery },
          });
          if (!queryRef.current) return;
          if (!hits.length) setShowNoSearchError(true);
          const parsedHits = transformSearchResult(hits);
          setResults(parsedHits);
          setSearchLoading(false);
        } catch (e) {
          // Search failed due to some technical stuff.
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setSearchLoading(false);
      searchResultCallback(false);
      setResults([]);
    }
  };

  // Debounced search

  // Effect to handle query changes
  useEffect(() => {
    searchResultCallback(true);
    performSearch(query);
  }, [query]);

  const { config } = useRemoteConfig();

  // Clear input and results
  const handleClear = () => {
    setQuery("");
    setResults([]);
    searchResultCallback(false);
    setShowNoSearchError(false);
    addSearchParam({ search: "" });
    setSearchLoading(false);
  };

  return (
    <div className="w-full flex flex-col items-center mx-auto ">
      <InputWithClose {...{ setQuery, query, handleClear, placeholder: config.locale_home_search_placeholder }} />
      <div className="mt-4 max-w-[1000px] w-full flex justify-center items-center flex-col">
        {results.length > 0 && <TemplateCardWrapper templates={results || []} />}
        {showNoSearchError && (
          <div className="flex flex-col justify-center items-center w-full bg-red mt-4 prose">
            <Gif icon="noSearch" height="80px" width="80px" />
            <p>{config.locale_search_no_result}</p>
          </div>
        )}

        {searchLoading && (
          <div className="flex flex-col justify-center items-center w-full bg-red mt-4 prose">
            <Gif icon="search" height="100px" width="100px" />
            <p>{config.locale_search_inprogess}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchComponent;
