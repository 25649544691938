import { useContext, useEffect, useRef } from "react";
import useGetDataWithCache from "./useGetDataWithCache";
import { AuthContext } from "../context/AuthContext";

const useInitialUserCollectionDataFetch = () => {
  const { getData, data } = useGetDataWithCache("usersColellections");
  
  const state = useContext(AuthContext).state;
  const isAuthenticated = state.isAuthenticated;

  const timer = useRef();
  const checkCompleted = useRef(false);
  useEffect(() => {
    clearInterval(timer.current);
    if (checkCompleted.current || !isAuthenticated) return;
    timer.current = setInterval(() => {
      
      getData({}, true)
        .then((res) => {
          
          if (res) {
            checkCompleted.current = true;
            clearInterval(timer.current);
          }
        })
        .catch((err) => {
          clearInterval(timer.current);
          
        });
    }, 2000);
  }, [isAuthenticated]);

  return { userCollectionFetched: data?.id };
};

export default useInitialUserCollectionDataFetch;
